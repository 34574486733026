$(document).ready(function() {

    $('#fullpage').fullpage({
        licenseKey: 'EDD6E929-44654FC9-A744C55D-717FC95C',
        verticalCentered: true,
        scrollingSpeed: 600,
        responsiveWidth: 768,
        autoScrolling:true,
        scrollHorizontally: true,
        anchors:['step1', 'step2', 'step3', 'step4','step5', 'benefit1', 'benefit2', 'benefit3', 'benefit4', 'benefit5', 'summary'],
        navigation: true,
        navigationPosition: 'right',
        scrollBar: 'true',
    });

    $('#arrow_up').click(function() {
        fullpage_api.moveSectionUp();
    });

    $('#arrow_down').click(function() {
        fullpage_api.moveSectionDown();
    });

    if ( $(window).width() < 768 ) {
        fullpage_api.destroy('all');
    };

});